import React from "react";

function FullPageSimpleLoading({ message }) {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        zIndex: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ color: "white", fontSize: "1.5rem" }}>
        {message || "Loading..."}
      </div>
    </div>
  );
}

export default FullPageSimpleLoading;
