import Papa from "papaparse";
import React from "react";
import { FaCloudUploadAlt, FaDownload } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { getFileTypeFromURL } from "../../CommonFiles/Utils/ObjectUtils";
import "./QuestionUpload.css";

const CSV_COLUMNS = [
  "Type",
  "Question",
  "Answer",
  "Choices",
  "MediaUrl",
  "OverallMarks",
  "NegativeMarks",
  "AnswerKeywords",
  "AnswerKeywordMarks",
];

function QuestionUpload(props) {
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === "text/csv") {
      Papa.parse(selectedFile, {
        complete: (result) => {
          const headers = result.meta.fields;
          if (!CSV_COLUMNS.every((column) => headers.includes(column))) {
            toast.error("Invalid template, please use the provided template", {
              position: "top-center",
            });
            return;
          }

          if (result.data.length === 0) {
            toast.error("No data found in the file, please try again", {
              position: "top-center",
            });
            return;
          }

          const processedData = result.data.map((row) => {
            if (row.Type === "MCQ") {
              const answers = row.Answer.split("|")
                .map((s) => s.trim())
                .filter((s) => s.length > 0);
              const choicesArray = row.Choices.split("|")
                .map((s) => s.trim())
                .filter((s) => s.length > 0);
              const choices = choicesArray.map((choice, index) => ({
                id: index + 1,
                choice: choice,
                type: "text",
              }));
              const mediaType =
                row.MediaUrl !== "" ? getFileTypeFromURL(row.MediaUrl) : null;

              return {
                questionType: "MCQ",
                questionText: `<p>${row.Question}</p>`,
                questionMediaUrls: mediaType
                  ? [{ mediaType: mediaType, mediaUrl: row.MediaUrl }]
                  : [],
                mcqType:
                  answers.length === 1 ? "Single Answer" : "Multiple Answer",
                choices: choices,
                answers: answers.map((answer) => {
                  if (choices.find((choice) => choice.choice === answer)) {
                    return choices.find((choice) => choice.choice === answer)
                      .id;
                  }
                  return null;
                }),
                overallMarks: isNaN(Number(row.OverallMarks))
                  ? 0
                  : Number(row.OverallMarks),
                negativeMarks: isNaN(Number(row.NegativeMarks))
                  ? 0
                  : Number(row.NegativeMarks),
                isDraft: true,
              };
            } else if (row.Type === "Subjective") {
              const keywords = row.AnswerKeywords.split("|")
                .map((s) => s.trim())
                .filter((s) => s.length > 0);
              const answerKeywordMarks = isNaN(Number(row.AnswerKeywordMarks))
                ? null
                : Number(row.AnswerKeywordMarks);
              const mediaType =
                row.MediaUrl !== "" ? getFileTypeFromURL(row.MediaUrl) : null;
              return {
                questionType: "Subjective",
                questionText: `<p>${row.Question}</p>`,
                questionMediaUrls: mediaType
                  ? [{ mediaType: mediaType, mediaUrl: row.MediaUrl }]
                  : [],
                answer: row.Answer,
                keywords: keywords.map((k, index) => {
                  return {
                    id: index + 1,
                    keywordVariants: [k],
                    marks: answerKeywordMarks === 0 ? null : answerKeywordMarks,
                  };
                }),
                markingScheme: answerKeywordMarks ? 2 : 1,
                keywordsSettings: 1,
                overallMarks: answerKeywordMarks
                  ? answerKeywordMarks * keywords.length
                  : isNaN(Number(row.OverallMarks))
                    ? 0
                    : Number(row.OverallMarks),
                negativeMarks: isNaN(Number(row.NegativeMarks))
                  ? 0
                  : Number(row.NegativeMarks),
                isDraft: true,
              };
            } else {
              return null;
            }
          });
          props.onExtractCompleted(processedData);
        },
        header: true, // Treat first row as column names
        skipEmptyLines: true, // Ignore empty lines
      });
    } else {
      alert("Please upload a valid CSV file.");
    }
  };

  const handleDownloadSample = () => {
    const link = document.createElement("a");
    link.href =
      "https://gradimages.s3.ap-south-1.amazonaws.com/1737571577237-Question-Bulk-Upload-Template.csv";
    link.download = "Question-Bulk-Upload-Template.csv";
    link.click();
  };

  return (
    <div className="upload-container">
      <ToastContainer />
      <h2>Upload .csv file for questions</h2>
      <div className="upload-box">
        <label htmlFor="file-upload" className="upload-label">
          <FaCloudUploadAlt className="upload-icon" />
          <span>Click to Upload .csv file</span>
          <p>Maximum file size is 10 MB</p>
          <input
            id="file-upload"
            type="file"
            accept=".csv"
            onChange={(e) => {
              handleFileChange(e);
              e.target.value = null;
            }}
            className="file-input"
          />
        </label>
      </div>

      <h3>Follow these steps to upload the .csv file-</h3>
      <ul className="instructions">
        <li>
          Download the Sample sheet and fill Questions and Solutions in
          respective columns.
        </li>
        <li>
          Use 'Subjective' for Subjective Questions and 'MCQ' for Multiple
          Choice Questions in 'Type' column.
        </li>
        <li>
          Please do not fill alphabets in 'OverallMarks', 'AnswerKeywordMarks',
          and 'NegativeMarks' columns.
        </li>
        <li>
          Use "|" as separator for 'Choices', 'Answer' and 'AnswerKeywords'
          column.
        </li>
        <li>If there are no question media Urls, leave the column blank.</li>
        <li>
          Do not modify or rearrange the sheet headers (i.e., column names) to
          avoid confusion.
        </li>
        <li>Only .csv file format can be uploaded.</li>
        <li>
          Check all the questions one by one and save them after modifications
          (if any).
        </li>
      </ul>

      <button onClick={handleDownloadSample} className="download-btn">
        <FaDownload className="download-icon" /> Download Sample
      </button>
    </div>
  );
}

export default QuestionUpload;
