import React from "react";

const EmptyState = ({ message }) => {
  return (
    <div className="empty-state">
      <div className="illustration">
        {/* Question mark illustration */}
        <div className="question-mark">
          <div className="circle"></div>
          <div className="dot"></div>
        </div>

        {/* Decorative elements */}
        <div className="floating-elements">
          <div className="element e1"></div>
          <div className="element e2"></div>
          <div className="element e3"></div>
          <div className="element e4"></div>
        </div>
      </div>
      <h2>{message}</h2>

      <style>{`
        .empty-state {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 40px;
          min-height: 400px;
          text-align: center;
        }

        h2 {
          color: #374151;
          font-size: 1.5rem;
          margin-top: 2rem;
          font-weight: 500;
        }

        .illustration {
          position: relative;
          width: 200px;
          height: 200px;
          animation: float 6s ease-in-out infinite;
        }

        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
        }

        .question-mark {
          position: relative;
          width: 100%;
          height: 100%;
        }

        .circle {
          position: absolute;
          top: 20%;
          left: 50%;
          transform: translateX(-50%);
          width: 80px;
          height: 80px;
          border: 8px solid rgb(241, 99, 99);
          border-radius: 50%;
          border-bottom-color: transparent;
          transform-origin: center;
          animation: rotate 20s linear infinite;
        }

        .dot {
          position: absolute;
          bottom: 30%;
          left: 50%;
          transform: translateX(-50%);
          width: 20px;
          height: 20px;
          background: #6366f1;
          border-radius: 50%;
        }

        .floating-elements {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }

        .element {
          position: absolute;
          border-radius: 50%;
          animation: floatElement 4s ease-in-out infinite;
        }

        .e1 {
          width: 12px;
          height: 12px;
          background: #fde68a;
          top: 20%;
          left: 20%;
          animation-delay: -1s;
        }

        .e2 {
          width: 16px;
          height: 16px;
          background: #93c5fd;
          top: 30%;
          right: 20%;
          animation-delay: -2s;
        }

        .e3 {
          width: 14px;
          height: 14px;
          background: #c084fc;
          bottom: 30%;
          left: 25%;
          animation-delay: -3s;
        }

        .e4 {
          width: 10px;
          height: 10px;
          background: #fb923c;
          bottom: 20%;
          right: 25%;
          animation-delay: -4s;
        }

        @keyframes rotate {
          0% { transform: translateX(-50%) rotate(0deg); }
          100% { transform: translateX(-50%) rotate(360deg); }
        }

        @keyframes floatElement {
          0%, 100% { transform: translate(0, 0); }
          50% { transform: translate(5px, -5px); }
        }
      `}</style>
    </div>
  );
};

export default EmptyState;
