import { FileTypes } from "../../User/quizManagement/QuestionAuthor";

export const trimObjectValues = (obj) => {
  let cleanedObj = {};

  for (let key in obj) {
    if (obj.hasOwnProperty(key) && typeof obj[key] === "string") {
      cleanedObj[key] = obj[key].trim();
    } else {
      cleanedObj[key] = obj[key];
    }
  }

  return cleanedObj;
};

export function isEqual(value1, value2, visited = new WeakMap()) {
  // Handle identical references or primitive equality
  if (value1 === value2) return true;

  // Handle `null` or `undefined`
  if (value1 == null || value2 == null) return value1 === value2;

  // Handle different types
  if (typeof value1 !== typeof value2) return false;

  // Handle special object types
  if (value1 instanceof Date && value2 instanceof Date) {
    return value1.getTime() === value2.getTime();
  }

  if (value1 instanceof RegExp && value2 instanceof RegExp) {
    return value1.toString() === value2.toString();
  }

  if (value1 instanceof Map && value2 instanceof Map) {
    if (value1.size !== value2.size) return false;
    return Array.from(value1.entries()).every(([key, val]) =>
      isEqual(val, value2.get(key))
    );
  }

  if (value1 instanceof Set && value2 instanceof Set) {
    if (value1.size !== value2.size) return false;
    return Array.from(value1).every((item) => value2.has(item));
  }

  // Handle arrays
  if (Array.isArray(value1) && Array.isArray(value2)) {
    if (value1.length !== value2.length) return false;
    return value1.every((item, index) => isEqual(item, value2[index]));
  }

  // Handle objects
  if (typeof value1 === "object" && typeof value2 === "object") {
    // Prevent infinite recursion for circular references
    if (visited.has(value1)) return visited.get(value1) === value2;
    visited.set(value1, value2);

    const keys1 = Object.keys(value1);
    const keys2 = Object.keys(value2);

    if (keys1.length !== keys2.length) return false;

    return keys1.every((key) => isEqual(value1[key], value2[key], visited));
  }

  // Fallback for unhandled cases
  return false;
}

export function getYearLabel(year) {
  const suffixes = ["th", "st", "nd", "rd"];
  const remainder = year % 10;
  const suffix =
    remainder === 1 && year !== 11
      ? suffixes[1]
      : remainder === 2 && year !== 12
        ? suffixes[2]
        : remainder === 3 && year !== 13
          ? suffixes[3]
          : suffixes[0];
  return `${year}${suffix} Year`;
}

export function getTextFromHTML(htmlContent) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent;
  return tempDiv.innerText || tempDiv.textContent;
}

export function getFileTypeFromURL(url) {
  const extension = url
    .split(".")
    .pop()
    .split("?")[0]
    .split("#")[0]
    .toLowerCase();

  const mimeTypes = Object.values(FileTypes)
    .flat()
    .reduce((acc, mimeType) => {
      const ext = mimeType.split("/").pop(); // Get the extension
      acc[ext] = mimeType; // Store in object
      return acc;
    }, {});

  return mimeTypes[extension] || null;
}
