import React, { useState } from "react";
import { IoMdCreate, IoMdTrash } from "react-icons/io";
import ReactStars from "react-rating-stars-component";
import CustomFieldModal from "../../DynamicPages/FormSteps/CustomFieldModal";
import { DefaultSurveyFormSettings } from "./QuizSettingPage";

function QuizSettingSurveySection({ currentSettings, setSettings }) {
  const [formData, setFormData] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  const [editingField, setEditingField] = useState(null);

  const handleChange = (e, rules) => {
    const { name, value } = e.target;

    if (rules) {
      const maxLengthRule = rules.find((rule) => rule.maxLength !== undefined);
      if (maxLengthRule && value.length > maxLengthRule.maxLength) {
        return;
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const renderField = (field) => {
    switch (field.fieldType) {
      case "text":
      case "email":
      case "number":
        return (
          <input
            className="form-control"
            type={field.fieldType}
            name={field.fieldName}
            placeholder={field.placeholder}
            onChange={(e) => handleChange(e, field?.rules)}
            value={formData[field.fieldName]}
          />
        );
      case "radio":
        return (
          <div className="radio-field">
            {field.options.map((option, index) => (
              <label key={index}>
                <input type="radio" name={field.fieldName} value={option} />
                {option}
              </label>
            ))}
          </div>
        );
      case "textarea":
        return (
          <textarea
            className="form-control"
            name={field.fieldName}
            placeholder={field.placeholder}
            maxLength={field.rules?.find((rule) => rule.maxLength)?.maxLength}
          />
        );
      case "checkbox":
        return (
          <div className="custom-field-checkbox-container">
            {field.options.map((option, index) => (
              <label key={index} className="custom-field-checkbox-item">
                <input
                  type="checkbox"
                  name={field.fieldName}
                  value={option}
                  className="form-control custom-field-checkbox"
                />
                {field.isTnCField ? (
                  <div className="tnc-text">
                    <div dangerouslySetInnerHTML={{ __html: option }} />
                  </div>
                ) : (
                  option
                )}
              </label>
            ))}
          </div>
        );

      case "select":
        return (
          <select className="form-control" name={field.fieldName}>
            {field.options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      case "file":
        return (
          <input className="form-control" type="file" name={field.fieldName} />
        );
      case "stars":
        return (
          <ReactStars
            count={5}
            size={30}
            value={formData[field.fieldName]}
            onChange={(newRating) => {
              setFormData({
                ...formData,
                [field.fieldName]: newRating,
              });
            }}
            activeColor="#ffd700"
          />
        );
      default:
        return null;
    }
  };

  const handleSaveField = (newField) => {
    if (editingField) {
      setSettings(
        currentSettings.map((field) => {
          if (field.fieldName === editingField.fieldName) {
            return newField;
          }
          return field;
        })
      );
      setEditingField(null);
      return;
    } else {
      setSettings([...currentSettings, newField]);
      setEditingField(null);
    }
  };

  const isDefaultField = (field) => {
    return DefaultSurveyFormSettings.find(
      (fieldData) => fieldData.fieldName === field.fieldName
    );
  };

  return (
    <div style={{ marginTop: "20px" }} className="form-group proctoring-group">
      <div className="form-row">
        <h4>Assessment Survey Form Preview</h4>
      </div>
      <div className="registration-form-container">
        {currentSettings.map((item, index) => {
          return (
            <div className={`input-field`} key={item.fieldName}>
              <div className="field-label-container">
                <label>
                  {item.label}
                  {item.isMandatory ? (
                    <span className="required-mark"> *</span>
                  ) : (
                    ""
                  )}
                </label>

                {!isDefaultField(item) && (
                  <IoMdCreate
                    className="delete-field-icon"
                    title="Edit Custom Field"
                    onClick={() => {
                      setEditingField(item);
                      setModalOpen(true);
                    }}
                  />
                )}

                {!isDefaultField(item) && (
                  <IoMdTrash
                    className="delete-field-icon"
                    title="Delete Custom Field"
                    onClick={() => {
                      const array = currentSettings.concat();
                      array.splice(index, 1);
                      setSettings(array);
                    }}
                  />
                )}
              </div>
              {renderField(item)}
            </div>
          );
        })}
      </div>
      <div className="add-field-section">
        <button
          className="add-field-button btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            setModalOpen(true);
          }}
        >
          + Add Custom Field
        </button>
      </div>

      <CustomFieldModal
        isOpen={isModalOpen}
        editingField={editingField}
        existingFields={currentSettings}
        onClose={() => {
          setModalOpen(false);
          setEditingField(null);
        }}
        onSave={handleSaveField}
        isSurveyFormSettings={true}
      />
    </div>
  );
}

export default QuizSettingSurveySection;
