import React from "react";
import { IoMdInformationCircle } from "react-icons/io";
import "./Tooltip.css";

const Tooltip = ({
  text,
  html,
  textSize = "14px",
  width = "260px",
  textAlign = "center",
}) => {
  return (
    <div className="tooltip-container">
      <IoMdInformationCircle className="tooltip-icon" color="black" />
      <div
        className="tooltip-text"
        style={{
          fontSize: textSize,
          width: width,
          textAlign: textAlign,
          padding: "10px",
        }}
      >
        {text && <p>{text}</p>}
        {html && <div dangerouslySetInnerHTML={{ __html: html }} />}
      </div>
    </div>
  );
};

export default Tooltip;
