import React from "react";

function SelectQuestionAddMethod({ onSelectMethod }) {
  return (
    <div className="select-question-add-method">
      <div className="cards-container">
        <div
          className="card create-questions"
          onClick={() => onSelectMethod(1)}
        >
          <div className="icon">
            <span className="plus">+</span>
          </div>
          <h3>Create Questions</h3>
          <p>
            From here you can add questions of multiple types like MCQs,
            subjective (text or paragraph)!
          </p>
        </div>
        <div className="card bulk-upload" onClick={() => onSelectMethod(2)}>
          <div className="icon">
            <span className="upload">⬆</span>
          </div>
          <h3>Bulk Upload</h3>
          <p>
            From here you can easily add multiple questions by uploading a CSV
            sheet!
          </p>
        </div>
      </div>
    </div>
  );
}

export default SelectQuestionAddMethod;
