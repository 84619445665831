import React from "react";
import { FaImage } from "react-icons/fa";

function MCQOptions({
  questionInfo,
  handleQuestionListItemChange,
  handleChoiceChange,
  handleAddChoice,
  handleDeleteChoice,
  errorList,
  handleRemoveError,
  handleFileUpload,
}) {
  const hasError = (errors, fieldName) => {
    if (errors.some((error) => error.field === fieldName)) {
      return errors.find((error) => error.field === fieldName).message;
    }
    return null;
  };

  return (
    <div className="mcq-options">
      <div className="mcq-type">
        <label>MCQ Type:</label>
        <select
          value={questionInfo.mcqType}
          onChange={(e) => {
            handleQuestionListItemChange("mcqType", e.target.value);
            handleQuestionListItemChange("answers", []);
          }}
        >
          <option value="Single Answer">Single Answer</option>
          <option value="Multiple Answer">Multiple Answer</option>
        </select>
      </div>

      <h4>
        Answer Options <span style={{ color: "red" }}>*</span>
      </h4>
      {questionInfo.choices.map((choice, index) => (
        <>
          <div
            key={index}
            className={`choice-item ${hasError(errorList, `choices[${index}]`) ? "no-bottom-margin" : ""}`}
          >
            <input
              type={
                questionInfo.mcqType === "Single Answer" ? "radio" : "checkbox"
              }
              name="answer"
              checked={questionInfo.answers.includes(choice.id)}
              onChange={(e) => {
                handleRemoveError("answers");
                if (e.target.checked) {
                  if (questionInfo.mcqType === "Single Answer") {
                    handleQuestionListItemChange("answers", [choice.id]);
                  } else {
                    handleQuestionListItemChange("answers", [
                      ...questionInfo.answers,
                      choice.id,
                    ]);
                  }
                } else {
                  handleQuestionListItemChange(
                    "answers",
                    questionInfo.answers.filter((id) => id !== choice.id)
                  );
                }
              }}
            />
            {(choice.type === null || choice.type === "text") && (
              <input
                type="text"
                className="choice-input"
                placeholder={`Enter Choice ${index + 1}`}
                value={choice.choice}
                onChange={(e) => {
                  handleChoiceChange(index, e.target.value);
                  handleRemoveError(`choices[${index}]`);
                }}
              />
            )}

            {choice.type === null && (
              <>
                <span className="separator-text">or</span>
                <span>
                  <input
                    id={`mcqOptionImage${index}`}
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      handleRemoveError(`choices[${index}]`);
                      handleFileUpload(
                        e.target.files[0],
                        "mcqOptionImage",
                        choice.id
                      );
                    }}
                    style={{ display: "none" }}
                  />
                  <label
                    htmlFor={`mcqOptionImage${index}`}
                    className="upload-photo-button"
                  >
                    <FaImage /> Upload Photo
                  </label>
                </span>
              </>
            )}

            {choice.type === "image" && (
              <div className="question-media">
                <div key={choice.id} className="question-media-item-image">
                  <img
                    src={choice.choice}
                    alt={`Choice ${index + 1}`}
                    className="question-media-image"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(choice.choice, "_blank");
                    }}
                    title="Click to view image in new tab"
                  />
                  <button
                    className="remove-media"
                    onClick={() => {
                      handleRemoveError(`choices[${index}]`);
                      handleChoiceChange(index, "");
                    }}
                    title="Remove image"
                  >
                    &times;
                  </button>
                </div>
              </div>
            )}

            {/* Add Delete Button if more than 2 choices */}
            {questionInfo.choices.length > 2 && (
              <button
                className="delete-choice-button"
                onClick={() => handleDeleteChoice(index)}
              >
                ✖
              </button>
            )}
          </div>
          {hasError(errorList, `choices[${index}]`) && (
            <p className="error-text">
              {hasError(errorList, `choices[${index}]`)}
            </p>
          )}
        </>
      ))}

      {hasError(errorList, "answers") && (
        <p className="error-text">{hasError(errorList, "answers")}</p>
      )}

      <button className="add-choice-button" onClick={handleAddChoice}>
        + Add Choice
      </button>
    </div>
  );
}

export default MCQOptions;
