import React from "react";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import Tooltip from "../../../CommonFiles/UI Elements/Tooltip/Tooltip";
import { KeywordSettings, MarkingSchemes } from "../SubjectiveStatics";

function SubjectiveOptions({
  questionInfo,
  handleQuestionListItemChange,
  handleAddKeyword,
  handleRemoveKeyword,
  handleAddKeywordVariant,
  handleRemoveKeywordVariant,
  handleKeywordVariantChange,
  handleKeywordMarksChange,
  errorList,
  handleRemoveError,
}) {
  const hasError = (errors, fieldName) => {
    if (errors.some((error) => error.field === fieldName)) {
      return errors.find((error) => error.field === fieldName).message;
    }
    return null;
  };

  const handleMarkingSchemeChange = (id) => {
    handleQuestionListItemChange("markingScheme", id);

    questionInfo.keywords.forEach((keyword, idx) => {
      handleKeywordMarksChange(keyword.id, null);
      handleRemoveError(`keywords[${idx}].marks`);
    });

    if (id === 2) {
      handleRemoveError("overallMarks");
    }
  };

  return (
    <div className="subjective-options">
      <h4>
        Answer <span style={{ color: "red" }}>*</span>
      </h4>
      <textarea
        placeholder="Enter your answer here..."
        value={questionInfo.answer}
        onChange={(e) => {
          handleQuestionListItemChange("answer", e.target.value);
          handleRemoveError("answer");
        }}
        style={{ marginBottom: hasError(errorList, "answer") ? "0px" : "20px" }}
      />
      {hasError(errorList, "answer") && (
        <p className="error-text">{hasError(errorList, "answer")}</p>
      )}
      <br />
      <div className="keywords-group">
        <h4>
          Keywords <span style={{ color: "red" }}>*</span>
          <Tooltip
            text={
              "You can add a variant for each keyword. For example, if the keyword is Shah Rukh Khan then its variant will be Shahrukh Khan, here marks will be awarded for both Shah Rukh Khan and Shahrukh Khan."
            }
            html={
              "<br/><ul><li>The keyword evaluation system is not case-sensitive.</li><li>Please avoid using any symbols in the keywords like +, - , @, $, #, *, etc.</li></ul>"
            }
            textSize="12px"
            textAlign="left"
            width="300px"
          />
        </h4>
        {questionInfo.keywords.map((keyword, idx) => (
          <React.Fragment key={idx}>
            <div className="keyword-variants">
              <div className="keyword-input">
                {keyword.keywordVariants.map((keywordVariant, index) => (
                  <React.Fragment key={index}>
                    <div className="keyword-variant-input">
                      <input
                        type="text"
                        placeholder={`${index === 0 ? `${idx + 1}. Keyword` : `Variant ${index}`}`}
                        value={keywordVariant}
                        className="form-control"
                        onChange={(e) => {
                          handleKeywordVariantChange(
                            keyword.id,
                            index,
                            e.target.value
                          );
                          handleRemoveError("keywords");
                        }}
                      />
                      {keyword.id === 1 && index === 0 ? null : (
                        <FaMinusCircle
                          className="remove-variant-button"
                          onClick={() => {
                            handleRemoveError("keywords");
                            if (index === 0) {
                              handleRemoveKeyword(keyword.id);
                            } else {
                              handleRemoveKeywordVariant(keyword.id, index);
                            }
                          }}
                        />
                      )}
                    </div>
                    {index !== keyword.keywordVariants.length - 1 && (
                      <span className="separator-text">or</span>
                    )}
                  </React.Fragment>
                ))}

                <button
                  className="add-variant-button"
                  onClick={() => {
                    handleAddKeywordVariant(keyword.id);
                    handleRemoveError("keywords");
                  }}
                  title="Add Variant"
                >
                  +
                </button>
              </div>

              {questionInfo.markingScheme === 2 && (
                <span>
                  <input
                    type="text"
                    placeholder="Marks"
                    value={keyword.marks}
                    className={`form-control keyword-marks-input`}
                    onChange={(e) => {
                      handleKeywordMarksChange(keyword.id, e.target.value);
                      handleRemoveError(`keywords[${idx}].marks`);
                    }}
                  />
                  {hasError(errorList, `keywords[${idx}].marks`) && (
                    <p className="error-text" style={{ maxWidth: "100px" }}>
                      {hasError(errorList, `keywords[${idx}].marks`)}
                    </p>
                  )}
                </span>
              )}
            </div>
            {idx !== questionInfo.keywords.length - 1 &&
              (questionInfo.keywordsSettings === 1 ? (
                <span className="separator-text">or</span>
              ) : (
                <span className="separator-text">and</span>
              ))}
          </React.Fragment>
        ))}
        {hasError(errorList, "keywords") && (
          <p className="error-text">{hasError(errorList, "keywords")}</p>
        )}
        <button
          className="add-keyword-button"
          onClick={() => {
            handleAddKeyword();
            handleRemoveError("keywords");
          }}
        >
          <FaPlusCircle /> Add Keyword
        </button>

        <br />

        <h4>
          Marking Scheme
          <Tooltip
            text={
              "Define whether you wish to assign overall marks or give different marks for each keyword"
            }
            textSize="12px"
            textAlign="left"
            width="300px"
          />
        </h4>
        <div className="marking-scheme-container">
          {MarkingSchemes.map((markingScheme) => (
            <div key={markingScheme.id}>
              <input
                type="radio"
                name="markingScheme"
                value={markingScheme.id}
                checked={questionInfo.markingScheme === markingScheme.id}
                onChange={() => handleMarkingSchemeChange(markingScheme.id)}
              />{" "}
              <label
                onClick={() => handleMarkingSchemeChange(markingScheme.id)}
                style={{ cursor: "pointer" }}
              >
                {markingScheme.name}
              </label>
            </div>
          ))}
        </div>

        <br />

        <h4>
          Keyword Settings
          <Tooltip
            text={
              "You can define whether you want to assign marks only if all the keywords in the answer are present or assign marks, either in case of overall or individual marking, even if a single keyword is present."
            }
            textSize="12px"
            textAlign="left"
            width="300px"
          />
        </h4>
        <div className="marking-scheme-container">
          {KeywordSettings.map((keywordSetting) => (
            <div key={keywordSetting.id}>
              <input
                type="radio"
                name="keywordSetting"
                value={keywordSetting.id}
                checked={questionInfo.keywordsSettings === keywordSetting.id}
                onChange={() =>
                  handleQuestionListItemChange(
                    "keywordsSettings",
                    keywordSetting.id
                  )
                }
              />{" "}
              <label
                onClick={() =>
                  handleQuestionListItemChange(
                    "keywordsSettings",
                    keywordSetting.id
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {keywordSetting.name}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SubjectiveOptions;
