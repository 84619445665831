import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Swal from "sweetalert2";
import QuestionAuthor from "./QuestionAuthor";
import QuestionUpload from "./QuestionUpload";
import "./quizsetting.css";
import SelectQuestionAddMethod from "./SelectQuestionAddMethod";

function AddQuestions(props) {
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [quizSettings, setQuizSettings] = useState(props.quizSettings);
  const [questionList, setQuestionList] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  React.useEffect(() => {
    if (props.quizSettings && props.quizSettings.questionIds.length > 0) {
      setSelectedMethod(1);
    }
  }, [props.quizSettings]);

  const prevStep = () => {
    if (hasUnsavedChanges) {
      Swal.fire({
        title: "Are you sure?",
        text: "Your unsaved changes will be lost.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          props.onQuizSettingChange(quizSettings);
          localStorage.setItem("quizStep", 1);
          props.newActiveStepValue(1);
        }
      });
    } else {
      props.onQuizSettingChange(quizSettings);
      localStorage.setItem("quizStep", 1);
      props.newActiveStepValue(1);
    }
  };

  const handleQuestionUpload = (newQuestionList) => {
    setQuestionList((prevQuestionList) => {
      if (prevQuestionList) return [...prevQuestionList, ...newQuestionList];
      return newQuestionList;
    });
    setSelectedMethod(1);
  };

  return (
    <div className="quizsettingsection">
      {(!quizSettings.questionIds || quizSettings.questionIds.length === 0) &&
        !selectedMethod && (
          <SelectQuestionAddMethod
            onSelectMethod={(newValue) => setSelectedMethod(newValue)}
          />
        )}

      {selectedMethod === 1 && (
        <QuestionAuthor
          quizSettings={quizSettings}
          questionList={questionList}
          onGoBack={() => setSelectedMethod(null)}
          onQuestionListUpdate={(newList) => setQuestionList(newList)}
          onQuizSettingChange={(newSettings) => {
            setQuizSettings(newSettings);
          }}
          onBulkUploadClick={() => setSelectedMethod(2)}
          hasUnsavedChanges={(value) => setHasUnsavedChanges(value)}
        />
      )}

      {selectedMethod === 2 && (
        <QuestionUpload onExtractCompleted={handleQuestionUpload} />
      )}

      <div className="hosting_bottom">
        <Button
          className="help-btn"
          type="button"
          onClick={() => {
            prevStep();
          }}
        >
          <FaArrowLeft />
          Go Back
        </Button>
        <Button
          className="save-hosting-btn"
          type="button"
          onClick={() => {
            props.onQuizSettingSave(quizSettings);
          }}
          disabled={
            !quizSettings.questionIds ||
            quizSettings.questionIds.length === 0 ||
            hasUnsavedChanges
          }
        >
          Save and Submit <FaArrowRight />
        </Button>
      </div>
    </div>
  );
}

export default AddQuestions;
