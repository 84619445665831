// Never change these values, this is the default value

export const MarkingSchemes = [
  { id: 1, name: "Assign overall mark" },
  { id: 2, name: "Assign marks to individual keywords" },
];

export const KeywordSettings = [
  { id: 1, name: "Assign marks if any keyword is present" },
  { id: 2, name: "Assign marks if all keywords are present" },
];
